// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--f036f";
export var bannerHeading = "Banner-module--bannerHeading--26878";
export var btn = "Banner-module--btn--83136";
export var curve = "Banner-module--curve--1898c";
export var dedicated = "Banner-module--dedicated--c5416";
export var dedicatedBanner = "Banner-module--dedicatedBanner--8703e";
export var dedicatedHeading = "Banner-module--dedicatedHeading--e3ef2";
export var description = "Banner-module--description--9f55f";
export var innerDedicated = "Banner-module--innerDedicated--b0bd4";
export var newBtn = "Banner-module--newBtn--1349f";
export var subTitle = "Banner-module--subTitle--78d33";