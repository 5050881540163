// extracted by mini-css-extract-plugin
export var SliderWidth = "Partner-Success-module--SliderWidth--5fdd7";
export var blueText = "Partner-Success-module--blueText--606c0";
export var clientContent = "Partner-Success-module--clientContent--2190e";
export var coma = "Partner-Success-module--coma--d3164";
export var content = "Partner-Success-module--content--36f2b";
export var customModal = "Partner-Success-module--customModal--e051d";
export var dec = "Partner-Success-module--dec--61fca";
export var designation = "Partner-Success-module--designation--52a2d";
export var dot = "Partner-Success-module--dot--54e6d";
export var heading = "Partner-Success-module--heading--74427";
export var iconContainer = "Partner-Success-module--iconContainer--2a151";
export var iconContainerLeft = "Partner-Success-module--iconContainerLeft--5e3ab";
export var imageContainer = "Partner-Success-module--imageContainer--4229e";
export var logos = "Partner-Success-module--logos--0c3e9";
export var logosBg = "Partner-Success-module--logosBg--90a29";
export var name = "Partner-Success-module--name--940a1";
export var partnerSuccess = "Partner-Success-module--partnerSuccess--aa246";
export var playBtn = "Partner-Success-module--playBtn--ade4b";
export var portfolioArrowIcon = "Partner-Success-module--portfolioArrowIcon--9f306";
export var portfolioArrowIconCover = "Partner-Success-module--portfolioArrowIconCover--82946";
export var portfolioArrowRightIconCover = "Partner-Success-module--portfolioArrowRightIconCover--b92ac";
export var view = "Partner-Success-module--view--4af57";