// extracted by mini-css-extract-plugin
export var arrowAnimation = "ITHealth-module--arrowAnimation--2950d";
export var black = "ITHealth-module--black--266e0";
export var card = "ITHealth-module--card--05af9";
export var card1 = "ITHealth-module--card1--09b58";
export var card2 = "ITHealth-module--card2--edd7e";
export var card3 = "ITHealth-module--card3--feeed";
export var colGap = "ITHealth-module--colGap--022af";
export var concerns = "ITHealth-module--concerns--b54be";
export var description = "ITHealth-module--description--1395d";
export var heading = "ITHealth-module--heading--403dc";
export var izValue = "ITHealth-module--izValue--aac84";
export var newBtn = "ITHealth-module--newBtn--9d5dc";
export var progress = "ITHealth-module--progress--07718";
export var secondIndex = "ITHealth-module--secondIndex--31688";
export var subDescription = "ITHealth-module--subDescription--207eb";
export var subHeading = "ITHealth-module--subHeading--e48ec";
export var talkBtn = "ITHealth-module--talkBtn--25df4";
export var white = "ITHealth-module--white--5b34c";