// extracted by mini-css-extract-plugin
export var arrowAnimation = "FlexibleEngagement-module--arrowAnimation--b4214";
export var blackButton = "FlexibleEngagement-module--blackButton--876cb";
export var blueButton = "FlexibleEngagement-module--blueButton--4c56a";
export var card = "FlexibleEngagement-module--card--65cf0";
export var colGap = "FlexibleEngagement-module--colGap--b95c7";
export var description = "FlexibleEngagement-module--description--ad9f2";
export var heading = "FlexibleEngagement-module--heading--2873b";
export var newBtn = "FlexibleEngagement-module--newBtn--4e4c5";
export var newBtn2 = "FlexibleEngagement-module--newBtn2--79f15";
export var progress = "FlexibleEngagement-module--progress--eb336";
export var progressSec = "FlexibleEngagement-module--progressSec--63031";
export var subHeading = "FlexibleEngagement-module--subHeading--02a59";
export var subTitle = "FlexibleEngagement-module--subTitle--00cdc";