// extracted by mini-css-extract-plugin
export var arrowAnimation = "FutureProofing-module--arrowAnimation--61f8d";
export var black = "FutureProofing-module--black--f4b97";
export var borderBottom = "FutureProofing-module--borderBottom--d2523";
export var borderBottomRight = "FutureProofing-module--borderBottomRight--34104";
export var borderLeftBottom = "FutureProofing-module--borderLeftBottom--03712";
export var borderRight = "FutureProofing-module--borderRight--1f88f";
export var card = "FutureProofing-module--card--364e7";
export var card1 = "FutureProofing-module--card1--616ba";
export var card2 = "FutureProofing-module--card2--8331d";
export var card3 = "FutureProofing-module--card3--61e99";
export var colGap = "FutureProofing-module--colGap--64b80";
export var colorBlue = "FutureProofing-module--colorBlue--c5d1f";
export var concerns = "FutureProofing-module--concerns--cf1b4";
export var description = "FutureProofing-module--description--c2c22";
export var heading = "FutureProofing-module--heading--30c7c";
export var izEdge = "FutureProofing-module--izEdge--0e514";
export var newBtn = "FutureProofing-module--newBtn--6c380";
export var progress = "FutureProofing-module--progress--8571f";
export var subDescription = "FutureProofing-module--subDescription--81bc4";
export var subHeading = "FutureProofing-module--subHeading--2b674";
export var talkBtn = "FutureProofing-module--talkBtn--84d13";
export var white = "FutureProofing-module--white--4dcae";