import React from "react"
import { Link } from "gatsby"
import * as styles from "./Trusted.module.scss"
import { Col, Container, Row } from "react-bootstrap"

function Trusted({ strapiData }) {
  console.log("🚀 ~ Trusted ~ strapiData:", strapiData)
  return (
    <div className={styles.trusted}>
      <Container>
        <Row>
          <Col lg={6}>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <p className={styles.desc}>
              Our structured approach ensures the highest standards of service
              in designing, implementing, and maintaining your IT
              infrastructure.
            </p>
          </Col>
          {/* <Col lg={6}>
            <div className={styles.trustedCards}>
              {strapiData?.cards?.map((e, i) => (
                <>
                <ul className={styles.blueBullets}>
                  <p>{e?.title}</p>
                </ul>
                  <p className={styles.cardSubTitle}>{e?.subTitle}</p>
                </>
              ))}
            </div>
          </Col> */}
          <Col lg={6}>
            <div className={styles.trustedCards}>
              {strapiData?.cards?.map((e, i) => (
                <div key={i} className={styles.cardItem}>
                  <p className={styles.cardTitle}>{e?.title}</p>
                  <p className={styles.cardSubTitle}>{e?.subTitle}</p>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Trusted
