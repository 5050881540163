// extracted by mini-css-extract-plugin
export var card = "IdeaToImpact-module--card--07278";
export var cardWrapper = "IdeaToImpact-module--cardWrapper--5b014";
export var description = "IdeaToImpact-module--description--f73f2";
export var enterpriseBuildBg = "IdeaToImpact-module--enterpriseBuildBg--66824";
export var fixed = "IdeaToImpact-module--fixed--02a76";
export var headInline = "IdeaToImpact-module--headInline--957bd";
export var hire = "IdeaToImpact-module--hire--10036";
export var hireCyber = "IdeaToImpact-module--hireCyber--d90cc";
export var imgCol = "IdeaToImpact-module--imgCol--49567";
export var leftCol = "IdeaToImpact-module--leftCol--9f061";
export var number = "IdeaToImpact-module--number--c9842";
export var start = "IdeaToImpact-module--start--dfd76";
export var subHeading = "IdeaToImpact-module--subHeading--e72b8";