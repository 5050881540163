// extracted by mini-css-extract-plugin
export var arrowAnimation = "DedicatedSoftwareTeam-module--arrowAnimation--17284";
export var blueBullets = "DedicatedSoftwareTeam-module--blueBullets--af458";
export var boxContent = "DedicatedSoftwareTeam-module--boxContent--59c56";
export var bulletIcon = "DedicatedSoftwareTeam-module--bulletIcon--5e8c9";
export var cards = "DedicatedSoftwareTeam-module--cards--c10b8";
export var cardsContent = "DedicatedSoftwareTeam-module--cardsContent--33497";
export var cardsZ = "DedicatedSoftwareTeam-module--cardsZ--9be75";
export var desc = "DedicatedSoftwareTeam-module--desc--0ad77";
export var featureItem = "DedicatedSoftwareTeam-module--featureItem--3d18c";
export var featuresList = "DedicatedSoftwareTeam-module--featuresList--21ce3";
export var heading = "DedicatedSoftwareTeam-module--heading--c65bf";
export var newBtn = "DedicatedSoftwareTeam-module--newBtn--02913";