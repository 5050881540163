// extracted by mini-css-extract-plugin
export var arrowAnimation = "IZ-Contribute-module--arrowAnimation--853df";
export var black = "IZ-Contribute-module--black--435d2";
export var card = "IZ-Contribute-module--card--f4566";
export var card1 = "IZ-Contribute-module--card1--b2bdc";
export var card2 = "IZ-Contribute-module--card2--beee5";
export var card3 = "IZ-Contribute-module--card3--bee22";
export var colGap = "IZ-Contribute-module--colGap--3224f";
export var colorBlue = "IZ-Contribute-module--colorBlue--b6f96";
export var concerns = "IZ-Contribute-module--concerns--07518";
export var description = "IZ-Contribute-module--description--f37a6";
export var heading = "IZ-Contribute-module--heading--68117";
export var headingWrapper = "IZ-Contribute-module--headingWrapper--36ce0";
export var izEdge = "IZ-Contribute-module--izEdge--8df23";
export var newBtn = "IZ-Contribute-module--newBtn--94340";
export var progress = "IZ-Contribute-module--progress--aebf0";
export var subDescription = "IZ-Contribute-module--subDescription--190a8";
export var subHeading = "IZ-Contribute-module--subHeading--b8d63";
export var talkBtn = "IZ-Contribute-module--talkBtn--1bfc6";
export var white = "IZ-Contribute-module--white--33d36";