// extracted by mini-css-extract-plugin
export var Trust = "SuccessfulProjects-module--Trust--73ef6";
export var arrowIcon = "SuccessfulProjects-module--arrowIcon--f60f2";
export var arrowImage = "SuccessfulProjects-module--arrowImage--7d073";
export var arrowLink = "SuccessfulProjects-module--arrowLink--43d91";
export var btn = "SuccessfulProjects-module--btn--62d7f";
export var btn2 = "SuccessfulProjects-module--btn2--4e4fb";
export var caseImages = "SuccessfulProjects-module--caseImages--030e1";
export var clientImgs = "SuccessfulProjects-module--clientImgs--68876";
export var content = "SuccessfulProjects-module--content--81571";
export var desc = "SuccessfulProjects-module--desc--a181e";
export var headSearch = "SuccessfulProjects-module--headSearch--4aba2";
export var heading = "SuccessfulProjects-module--heading--56330";
export var logoImages = "SuccessfulProjects-module--logoImages--26fbd";
export var logos = "SuccessfulProjects-module--logos--9febd";
export var rowGap = "SuccessfulProjects-module--rowGap--614f2";
export var talkBtn = "SuccessfulProjects-module--talkBtn--d34fc";